#app {
  height: 100%
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-size: 14px;
  outline: none;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 50px;
}
/* resolve slideToClickedSlide not work */
.swiper-wrapper {
  pointer-events: none;
}
@media screen and (min-width:1024px) {
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 370px;
    pointer-events: auto;/* resolve slideToClickedSlide not work */
  }
}

@media screen and (max-width:1023px) {
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 340px;
    pointer-events: auto;/* resolve slideToClickedSlide not work */
  }
}

.swiper-slide img {
  display: block;
  width: 100%;
}



#payment-form {
  width: 100%;
  align-self: center;
  background-color: rgb(246, 246, 246);
  padding: 30px;
}

#payment-message {
  color: rgb(230, 15, 68);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

#payment-element {
  margin-bottom: 24px;
}
a:active{
  color: #24d698;
}
@media (any-hover: hover){
  a:hover{
    color:#24D698;
  }
}

.grayimg{
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}